<template lang="pug">
v-form(
  :disabled="disabled"
)
  v-text-field(
    v-model="value.surname"
    label="Фамилия"
    :error-messages="getValidationErrors('value.surname')"
    @blur="$v.value.surname.$touch()"
  )
  v-text-field(
    v-model="value.name"
    label="Имя"
    :error-messages="getValidationErrors('value.name')"
    @blur="$v.value.name.$touch()"
  )
  v-text-field(
    v-model="value.patronymic"
    label="Отчество"
    :error-messages="getValidationErrors('value.patronymic')"
    @blur="$v.value.patronymic.$touch()"
  )

  v-text-field(
    v-model="value.organization"
    label="Организация"
    :error-messages="getValidationErrors('value.organization')"
    hintColor="orange"
    @blur="$v.value.organization.$touch()"
  )
  v-text-field(
    v-model="value.position"
    label="Позиция"
    :error-messages="getValidationErrors('value.position')"
    @blur="$v.value.position.$touch()"
  )
</template>

<script>
import validationMixin from '@/utils/validation';
import { cyrillicName } from '@/utils/validators';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],

  props: {
    value: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
  },

  validations: {
    value: {
      name: { required, cyrillicName, maxSymbolsLength: maxLength(64) },
      surname: { required, cyrillicName, maxSymbolsLength: maxLength(64) },
      patronymic: { required, cyrillicName, maxSymbolsLength: maxLength(64) },
      organization: {
        required,
        minSymbolsLength: minLength(4),
        maxSymbolsLength: maxLength(30),
      },
      position: {
        required,
        minSymbolsLength: minLength(3),
        maxSymbolsLength: maxLength(20),
      },
    },
  },

  methods: {
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
  },
};
</script>
