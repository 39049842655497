<template lang="pug">
v-dialog(  :value="true"  max-width="500px" @input="$emit('close')")
  v-card
      v-card-title.d-flex.justify-space-between
        div Редактировать профиль 
        div {{email}}

      v-card-text
        EditProfileForm(v-model="profile" ref="form" :disabled="isLoading")

        v-row(align-end)
          v-col(cols="12" sm="6")
            v-btn(
              @click="$emit('close')"
              outlined
              block
            ) Отмена
          v-col(cols="12" sm="6")
            v-btn(
              @click="onSubmit"
              block
              depressed
              color="primary"
              :loading="isLoading"
            ) Сохранить
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EditProfileForm from '@/views/accounts/components/EditProfileForm';

export default {
  components: {
    EditProfileForm,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      profile: {},
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('ACCOUNTS', ['listItem']),
    email() {
      return this.listItem(this.id)?.email;
    },
  },

  created() {
    this.profile = { ...this.listItem(this.id).profile };
  },

  methods: {
    ...mapActions('ACCOUNTS', ['changeProfile']),

    async onSubmit() {
      if (!this.$refs.form.validate()) return;
      this.isLoading = true;

      try {
        await this.changeProfile({ id: this.id, profile: this.profile });
        this.$notify({
          group: 'note',
          type: 'info',
          title: `Профиль пользователя ${this.email} успешно изменен`,
        });
        this.$emit('close');
      } catch (error) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Ошибка изменения профиля пользователя ' + this.email,
          text: error,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
